<template>
  <div id="about" v-show="!showMenu && hideLoading" ref="about">
    <h1>{{title}}</h1>
    <div v-html="content" class="about-content"/>
    <div class="shadow-img-t">
      <img alt="" class="shadow-img" src="../assets/images/shadow/shadow.png">
    </div>
    <div class="shadow-img-b">
      <img alt="" class="shadow-img" src="../assets/images/shadow/shadow.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  props: ['showMenu', 'hideLoading'],
  data: () => ({
    title: '',
    content: ''
  }),
  async mounted() {
    const languages = await this.$store.dispatch('fetchLanguages')
    let LANGUAGE

    let lLanguages = []

    let tempArr = []

    Object.keys(languages).forEach(key => {
      lLanguages.push(languages[key])
      if (languages[key].visible) {
        tempArr.push({
          title: languages[key].name,
          flag: languages[key].images.flag,
          id: key,
          pos: languages[key].pos
        })
      }
    })

    lLanguages.sort((a, b) => a.pos - b.pos)

    try {
      const localLanguage = JSON.parse(localStorage.getItem('language'))

      if (localLanguage && languages[localLanguage.id] && languages[localLanguage.id].visible) {
        LANGUAGE = localLanguage
      }
      else {
        // eslint-disable-next-line no-unused-vars
        for (let lan of lLanguages) {
          if (lan.visible) {
            LANGUAGE = {id: lan.id, name: lan.name}
            break
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-unused-vars
      for (const [_, value] of Object.entries(languages)) {
        if (value.visible){
          LANGUAGE = {id: value.id, name: value.name}
          break
        }
      }
    }

    const currLanguage = languages[LANGUAGE.id]

    this.title = currLanguage.pages.about.title
    this.content = currLanguage.pages.about.content
  }
}
</script>

<style lang="sass">
$thresholdSides: calc(20px + 120 * ((100vw - 320px) / (1920 - 320)))
$thresholdSidesM: calc( (20px + 120 * ((100vw - 320px) / (1920 - 320))) * -1)
$forImg: calc(100% + ( 2 * (20px + 120 * ((100vw - 320px) / (1920 - 320))) ) )
#about
  max-width: 1086px
  height: auto
  margin: auto
  padding-top: 110px
  padding-bottom: 100px

  background-color: #fff
  color: #000
  h1
    margin-bottom: 15px
    text-align: center
    font-family: 'Galderglynn1884SqDb', sans-serif
    font-size: calc(80px + 30 * ((100vw - 320px) / (1920 - 320)))
    line-height: calc(96px + 34 * ((100vw - 320px) / (1920 - 320)))

    letter-spacing: 0.02em
    font-weight: 500
.about-content
  text-indent: 30px
  overflow-wrap: break-word
  letter-spacing: 0.02em
  padding: 10px $thresholdSides
  & > img
    width: $forImg
    margin: 42px 0 42px $thresholdSidesM
</style>